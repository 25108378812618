.kontakt {
  margin-top: 5vh;
}
.kontakt_info {
  width: 50%;
}
.kontakt_container {
  display: flex;
  height: 90vh;
}
.konakt_boxes {
  width: 28vw;
  display: flex;
}
.kontakt_info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.kontakt_section_googlemaps {
  width: 50%;
  display: flex;
  align-items: center;
}
.konakt_boxes_boxes {
  margin: 7px;
  font-weight: 300;
}
.konakt_boxes_boxes > h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.konakt_boxes_boxes:nth-of-type(2) > h4:nth-of-type(5) {
  margin-top: 10px;
}
.kontakt_image1 {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 30vh;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/almont-d855e.appspot.com/o/assets%2FWhatsApp%20Image%202022-09-26%20at%2019.47.00.jpeg?alt=media&token=be587840-b08d-42f9-b428-c71ea1db9719);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  -webkit-transition: background-size 0.5s;
  transition: background-size 0.5s;
}
.kontakt_image1:hover {
  background-size: 103%;
}
.kontakt_image1 > h1 {
  margin-top: 3vh;
}
.kontakt_boxes_info {
  margin-bottom: 3vh;
  font-weight: 600;
}
.kontakt_nip {
  margin-top: 0 !important;
}
h4 {
  font-weight: 400;
}
@media only screen and (max-width: 795px) {
  .kontakt_container {
    flex-direction: column;
    height: fit-content;
  }
  .kontakt_info {
    width: 100vw;
  }
  .konakt_boxes {
    margin: 1vh 0;
    display: inline-block;
    text-align: center;
    width: 100vw;
  }
  .kontakt_section_googlemaps {
    width: 100vw;
    height: 50vh;
  }
  .kontakt_boxes_info {
    font-size: 1.6rem;
    margin-top: 1rem;
    margin: 0 auto;
  }
}
