.home {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.home_main_section {
  position: relative;
  height: 90vh;
  margin-top: 10vh;
  background-position: top;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/bestpol-64906.appspot.com/o/assets%2F20221212_120724.jpg?alt=media&token=ac48f8cd-9bf2-4160-a00a-addbb1d12e7d);
  background-size: cover;
}

.home_main_section_heading_bottom {
  top: 20%;
  right: 5%;
  position: absolute;
  color: white;
  font-weight: 100;
  font-size: 2rem;
  font-family: "Raleway", sans-serif;
  width: 30vw;
  text-align: center;
}

.home_main_section_heading_top {
  top: 5%;
  right: 5%;
  position: absolute;
  color: white;
  font-weight: 300;
  font-size: 5rem;
  font-family: "Raleway", sans-serif;
  width: 50vw;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 900px) {
  .home_main_section_heading_top {
    top: 10%;
    left: 5%;
    position: absolute;
    color: white;
    font-weight: 300;
    font-size: 5rem;
    font-family: "Raleway", sans-serif;
    width: 50vw;
    display: flex;
    justify-content: flex-end;
  }

  .home_main_section_heading_bottom {
    top: 25%;
    right: 5%;
    position: absolute;
    color: white;
    font-weight: 100;
    font-size: 3rem;
    font-family: "Raleway", sans-serif;
    width: 50vw;
    text-align: end;
  }
}

@media only screen and (max-width: 560px) {
  .home_main_section {
    background-position: -100px;
  }
  .home_main_section_heading_top {
    text-align: right;
    top: 5%;
    left: 5%;
    position: absolute;
    /* color: #005fa3; */
    font-weight: 300;
    font-size: 3rem;
    font-family: "Raleway", sans-serif;
    width: 80vw;
    display: flex;
    justify-content: flex-start;
  }

  .home_main_section_heading_bottom {
    top: 20%;
    left: 5%;
    position: absolute;
    font-weight: 200;
    font-size: 1.5rem;
    font-family: "Raleway", sans-serif;
    width: 50vw;
    text-align: end;
    /* color: #005fa3; */
  }
}
