.container-slider {
  margin-top: 5vh;
  width: 100vw;
  height: 95vh;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
  display: flex;
  overflow-y: hidden;
}

.slide img {
  width: 100vw;
  height: 100%;
  object-fit: cover;
}
.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: none;
  border: none;
  /* border: 3px solid rgba(255, 255, 255, 0.649); */
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.btn-slide:hover {
  outline: none;
  border: 3px solid rgba(255, 255, 255, 0.649);
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}
.prev {
  top: 50%;
  left: 20px;
  transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 20px;
  transform: translateY(-60%);
}

.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  margin: 0 5px;
  background: none;
}
.dot.active {
  background: #ffffff;
}
.slider_title {
  position: absolute;
  top: 10%;
  right: 50%;
  transform: translate(50%, 0);
  height: 80px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2rem;
  transition: 0.5s;
  width: 90%;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.721);
  overflow: hidden;
}
.slider_title:hover {
  cursor: pointer;
}
.slider_title > img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}
.slide {
  display: none;
}
.active-anim {
  display: block;
}
.active-anim > a > div {
}

.slider_title:hover {
  text-decoration: underline;
}
